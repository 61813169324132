class MrOrganizations extends HTMLElement {
	static get observedAttributes() {
		return [
			'value',
		];
	}

	constructor() {
		// If you define a constructor, always call super() first!
		// This is specific to CE and required by the spec.
		super();

		this.data = null;
		this.index = 0;
		this.animateDelay = 4000;
		this.typeDelay = 80;
	}

	connectedCallback() {
		if ( !( 'IntersectionObserver' in window ) ) {
			return;
		}

		this.trySetData();
	}

	disconnectedCallback() {
		if ( this._observer ) {
			this._observer.disconnect();
			this._observer = null;
		}

		this.data = null;
		this.index = 0;

		this._didStartAnimation = false;
	}

	attributeChangedCallback( attrName ) {
		if ( 'value' === attrName ) {
			this.trySetData();

			return;
		}
	}

	trySetData() {
		try {
			const data = JSON.parse( this.getAttribute( 'value' ) );
			this.data = data;
		} catch ( err ) {
			console.warn( err );
		}

		this.bind();
	}

	bind() {
		// Wait for first (or next) frame render
		requestAnimationFrame( () => {
			this._observer = new IntersectionObserver( ( entries ) => {
				for ( let i = 0; i < entries.length; i += 1 ) {
					const entry = entries[i];

					if ( !entry || !entry.target ) {
						continue;
					}

					if ( entry.target === this && entry.isIntersecting ) {
						if ( this._didStartAnimation ) {
							return;
						}

						// Any errors that happen below are fatal and cannot be retried.
						this._didStartAnimation = true;

						if ( this._observer ) {
							this._observer.disconnect();
							this._observer = null;
						}

						this.play();
					}
				}
			} );

			this._observer.observe( this );
		} );

		window.addEventListener( 'resize', () => {
			this.render();
		} );
	}

	getNextIndex() {
		let index = this.index + 1;
		if ( index === this.data.length ) {
			index = 0;
		}

		return index;
	}

	prerender() {
		// no data no prerender
		if ( !this.data ) {
			return;
		}

		// no background no prerender
		this.background = this.querySelector( '[organizations-background]' );
		if ( !this.background ) {
			return;
		}

		// Check if current element has background_imgix_src
		if ( !this.data[this.index].background_imgix_src ) {
			return;
		}

		// Create new image (with the container it's width/height) and drop it inside the background
		const img = document.createElement( 'img' );
		img.classList.add( 'organizations__background__asset' );
		img.classList.add( 'organizations__background__asset--prerender' );
		img.src = this.data[this.getNextIndex()].background_imgix_src + this.getImgixConfiguration();
		this.background.appendChild( img );
	}

	render() {
		// no data no render
		if ( !this.data ) {
			return;
		}

		// no background or no link no render
		this.background = this.querySelector( '[organizations-background]' );
		this.link = this.querySelector( '[organizations-link]' );
		if ( !this.background || !this.link ) {
			return;
		}

		// first remove the attribute and if available add one
		this.link.removeAttribute( 'href' );
		if ( this.data[this.index].linked_content ) {
			this.link.setAttribute( 'href', this.data[this.index].linked_content );
		}

		// Clean up background
		this.background.innerHTML = '';

		// Check if current element has background_imgix_src
		if ( !this.data[this.index].background_imgix_src ) {
			return;
		}

		// Create new image (with the container it's width/height) and drop it inside the background
		const img = document.createElement( 'img' );
		img.classList.add( 'organizations__background__asset' );
		img.src = this.data[this.index].background_imgix_src + this.getImgixConfiguration();
		this.background.appendChild( img );
	}

	getImgixConfiguration() {
		return `?w=${this.clientWidth}&amp;h=${this.clientHeight}&amp;fit=crop&amp;auto=format&amp;q=60&amp;dpr=${window.devicePixelRatio}`;
	}

	play() {
		// no data no animation
		if ( !this.data ) {
			return;
		}

		// start automatically the next
		setTimeout( () => {
			this.showNext();
		}, 1000 );
	}

	showNext() {
		// Get elements and check if they are available
		this.label = this.querySelector( '[organizations-label]' );
		if ( !this.label ) {
			return;
		}

		// Make sure the next item is prerendered
		this.prerender();

		// start the delete loop
		this.cycleDelete();
	}

	cycleDelete() {
		if ( !this.label ) {
			return;
		}

		// Slice 3 characters per cycle
		this.label.innerHTML = this.label.innerHTML.slice( 0, Math.max( 0, this.label.innerHTML.length - 3 ) );

		if ( 1 >= this.label.innerHTML.length ) {
			// start the typing cycle
			this.index = this.getNextIndex();
			this.render();
			setTimeout( () => {
				this.cycleTyping();
			}, this.typeDelay );
		} else {
			// continue the delete cycle
			setTimeout( () => {
				this.cycleDelete();
			}, this.typeDelay );
		}
	}

	cycleTyping() {
		if ( !this.label ) {
			return;
		}

		// Add 1 charater per cycle
		this.label.innerHTML = this.data[this.index].label.slice( 0, this.label.innerHTML.length + 1 );

		if ( this.label.innerHTML.length < this.data[this.index].label.length ) {
			// repeat the typing cycle
			setTimeout( () => {
				this.cycleTyping();
			}, this.typeDelay );
		} else {
			// start automatically the next
			setTimeout( () => {
				this.showNext();
			}, this.animateDelay );
		}
	}
}

customElements.define( 'mr-organizations', MrOrganizations );
