class MrParticipants extends HTMLElement {
	connectedCallback() {
		if ( !( 'IntersectionObserver' in window ) ) {
			return;
		}

		// Wait for first (or next) frame render
		requestAnimationFrame( () => {
			this._observer = new IntersectionObserver( ( entries ) => {
				for ( let i = 0; i < entries.length; i += 1 ) {
					const entry = entries[i];

					if ( !entry || !entry.target ) {
						continue;
					}

					if ( entry.target === this && entry.isIntersecting ) {
						if ( this._didSetInnerHTML ) {
							return;
						}

						// Any errors that happen below are fatal and cannot be retried.
						// No point to set this to true after actually creating a map.
						this._didSetInnerHTML = true;

						if ( this._observer ) {
							this._observer.disconnect();
							this._observer = null;
						}

						this.setInnerHTML();
					}
				}
			} );

			this._observer.observe( this );
		} );
	}

	disconnectedCallback() {
		if ( this._observer ) {
			this._observer.disconnect();
			this._observer = null;
		}

		this.innerHTML = '';
		this.removeAttribute( 'is-loaded' );

		this._didSetInnerHTML = false;
	}

	async setInnerHTML() {
		try {
			const resp = await fetch( '/wp-json/participants/all.json' );
			if ( !resp.ok ) {
				console.log( resp );

				return;
			}

			const data = await resp.json();
			if ( !data || !data.html ) {
				return;
			}

			requestAnimationFrame( () => {
				this.innerHTML = data.html;
				this.setAttribute( 'is-loaded', 'is-loaded' );
			} );
		} catch ( err ) {
			console.warn( err );
		}
	}
}

customElements.define( 'mr-participants', MrParticipants );
