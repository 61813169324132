class MrAnimatedWords extends HTMLElement {
	static get observedAttributes() {
		return [
			'value',
		];
	}

	constructor() {
		// If you define a constructor, always call super() first!
		// This is specific to CE and required by the spec.
		super();

		this.data = null;
		this.index = 0;
		this.animateDelay = 4000;
		this.typeDelay = 80;
	}

	connectedCallback() {
		if ( !( 'IntersectionObserver' in window ) ) {
			return;
		}

		this.trySetData();
	}

	disconnectedCallback() {
		if ( this._observer ) {
			this._observer.disconnect();
			this._observer = null;
		}

		this.data = null;
		this.index = 0;

		this._didStartAnimation = false;
	}

	attributeChangedCallback( attrName ) {
		if ( 'value' === attrName ) {
			this.trySetData();

			return;
		}
	}

	trySetData() {
		try {
			const data = JSON.parse( this.getAttribute( 'value' ) );
			this.data = data;
		} catch ( err ) {
			console.warn( err );
		}

		this.bind();
	}

	bind() {
		// Wait for first (or next) frame render
		requestAnimationFrame( () => {
			this._observer = new IntersectionObserver( ( entries ) => {
				for ( let i = 0; i < entries.length; i += 1 ) {
					const entry = entries[i];

					if ( !entry || !entry.target ) {
						continue;
					}

					if ( entry.target === this && entry.isIntersecting ) {
						if ( this._didStartAnimation ) {
							return;
						}

						// Any errors that happen below are fatal and cannot be retried.
						this._didStartAnimation = true;

						if ( this._observer ) {
							this._observer.disconnect();
							this._observer = null;
						}

						this.play();
					}
				}
			} );

			this._observer.observe( this );
		} );
	}

	play() {
		// no data no animation
		if ( !this.data ) {
			return;
		}

		// start automatically the next
		setTimeout( () => {
			this.showNext();
		}, 1000 );
	}

	showNext() {
		// start the delete loop
		this.cycleDelete();
	}

	cycleDelete() {
		// Slice 3 characters per cycle
		this.innerHTML = this.innerHTML.slice( 0, Math.max( 0, this.innerHTML.length - 3 ) );

		if ( 1 >= this.innerHTML.length ) {
			// start the typing cycle
			this.index++;
			if ( this.index === this.data.length ) {
				this.index = 0;
			}
			setTimeout( () => {
				this.cycleTyping();
			}, this.typeDelay );
		} else {
			// continue the delete cycle
			setTimeout( () => {
				this.cycleDelete();
			}, this.typeDelay );
		}
	}

	cycleTyping() {
		// Add 1 charater per cycle
		this.innerHTML = this.data[this.index].slice( 0, this.innerHTML.length + 1 );

		if ( this.innerHTML.length < this.data[this.index].length ) {
			// repeat the typing cycle
			setTimeout( () => {
				this.cycleTyping();
			}, this.typeDelay );
		} else {
			// start automatically the next
			setTimeout( () => {
				this.showNext();
			}, this.animateDelay );
		}
	}
}

customElements.define( 'mr-animated-words', MrAnimatedWords );
